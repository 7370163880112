body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.formFooter {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes shimmer {
  0% {
    background-position: -10000px;
  }
  100% {
    background-position: 10000px;
  }
}

.textLayer{
  display: none;
}
.annotationLayer{
  display: none;
}

@media print {
  .pdf-container {
    display: none;
  }
}
